import React from 'react';
import styled from '@emotion/styled';
import { TColor, MOBILE_WIDTH } from '../styles/StyleConstants';
import SvgIcon from '@material-ui/core/SvgIcon';

export enum IconType {
    DOWNLOAD,
    PDF,
}

export enum ButtonType {
    ICON,
    DEFAULT,
}

export interface ButtonStyle {
    bgColor: TColor;
    hoverBgColor: TColor;
    hoverTextColor: TColor;
    textColor: TColor;
}

export const BUTTON_DEFAULT_STYLES = {
    bgColor: TColor.P4_LIGHT_BLUE,
    hoverBgColor: TColor.P3_TEAL,
    hoverTextColor: TColor.P1_WHITE,
    textColor: TColor.P2_SEAFOAM,
};

interface Props {
    buttonStyle: ButtonStyle;
    text: string;
    clickHandler?: () => void;
    iconType: IconType;
    buttonType?: ButtonType;
    customStyle?: string;
    disabled?: boolean;
    opacity?: number;
    pixelWidth?: number;
}

const DEFAULT_PADDING = '14px 20px';
const ICON_HEIGHT = '40px';

const PortalButton = (props: Props) => {
    const {
        buttonStyle,
        buttonType,
        clickHandler,
        customStyle,
        disabled,
        iconType,
        opacity,
        pixelWidth,
        text,
    } = props;
    const { bgColor, hoverBgColor, hoverTextColor, textColor } = buttonStyle;

    // I don't love this.
    function renderButtonImage() {
        if (iconType === IconType.DOWNLOAD) {
            return (
                <SvgIcon
                    style={{ fontSize: 21, marginRight: text === '' ? 0 : 8 }}
                >
                    <path d="M16 13h-3V3h-2v10H8l4 4 4-4zM4 19v2h16v-2H4z" />
                    <path d="M0 0h24v24H0z" fill="none" />
                </SvgIcon>
            );
        } else {
            return (
                <SvgIcon
                    height="15"
                    viewBox="0 0 25 15"
                    width="25"
                    style={{ fontSize: 21, marginRight: 8 }}
                >
                    <g fill="none" fillRule="evenodd">
                        <path
                            d="m4.94 10.6086957v-5.72000005h1.976c1.392 0 2.184.616 2.184 1.824v.032c0 1.208-.84 1.784-2.144 1.784h-.728v2.08000005zm1.288-2.98400005h.656c.632 0 .968-.304.968-.872v-.032c0-.608-.352-.848-.968-.848h-.656zm5.6826667 1.976h.528c1.152 0 1.696-.648 1.696-1.832v-.064c0-1.176-.504-1.808-1.712-1.808h-.512zm-1.296 1.00800005v-5.72000005h1.872c2.008 0 3 1.088 3 2.8v.064c0 1.712-1 2.85600005-3.008 2.85600005zm6.4746666 0v-5.72000005h3.672v1.016h-2.376v1.512h1.88v.976h-1.88v2.21600005z"
                            fill="#00343a"
                            fillRule="nonzero"
                        />
                        <path d="m.5.5h24v14h-24z" stroke="#00343a" />
                    </g>
                </SvgIcon>
            );
        }
    }

    function getWidth() {
        return buttonType === ButtonType.ICON
            ? ICON_HEIGHT
            : `${pixelWidth}px` || 'auto';
    }

    return (
        <Button
            bgColor={bgColor}
            customStyle={customStyle}
            disabled={disabled}
            height={buttonType === ButtonType.ICON ? ICON_HEIGHT : 'auto'}
            hoverBgColor={hoverBgColor}
            hoverTextColor={hoverTextColor}
            onClick={clickHandler}
            opacity={opacity === 0 ? 0 : 1}
            padding={buttonType === ButtonType.ICON ? '0' : DEFAULT_PADDING}
            width={getWidth()}
            textColor={textColor}
        >
            {renderButtonImage()}
            {text}
        </Button>
    );
};

// eslint-disable-next-line no-unexpected-multiline
const Button = styled.button<{
    bgColor: TColor;
    height: string;
    hoverBgColor: TColor;
    hoverTextColor: TColor;
    opacity: number;
    padding: string;
    textColor: TColor;
    width: string;
    customStyle?: string;
}>`
    align-items: center;
    box-sizing: border-box;
    background-color: ${props => props.bgColor};
    border: none;
    color: ${props => props.textColor};
    cursor: pointer;
    display: flex;
    font: 600 15px Graphik Web;
    height: ${props => props.height};
    justify-content: center;
    line-height: 18px;
    letter-spacing: 1.5px;
    max-width: 240px;
    opacity: ${props => props.opacity};
    padding: ${props => props.padding};
    :hover {
        background-color: ${props => props.hoverBgColor};
        color: ${props => props.hoverTextColor};
    }
    text-transform: uppercase;
    transition: all 300ms;
    width: ${props => props.width};
    -webkit-appearance: none;
    -moz-appearance: none;
    :focus {
        outline: none;
    }
    @media (max-width: ${MOBILE_WIDTH}) {
       display: none;
    }
    ${props => props.customStyle}
}
`;

export default PortalButton;
