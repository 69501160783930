import React, { useState, useContext } from 'react';
import styled from '@emotion/styled';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Grid from '@material-ui/core/Grid';

import { TallyEvent } from '../util/PortalDao';
import {
    TColor,
    MAX_WIDTH,
    MOBILE_WIDTH,
    DESKTOP_CONTAINER_PADDING,
    MOBILE_CONTAINER_PADDING,
} from '../styles/StyleConstants';
import tallyLogo from '../assets/images/TallyLogo.svg';
import downArrow from '../assets/images/DownArrow.png';
import {
    getAverageUsersPerEvent,
    getAverageNewPlayerMarketingOptIn,
    getAverageNewUserPerEvent,
} from '../util/DataHelpers';
import PlayerTrend from './visualizations/PlayerTrend';
import PlayerBreakdown from './visualizations/PlayerBreakdown';
import EmailOptInStats from './visualizations/EmailOptInStats';
import AppContext from '../contexts/AppContext';

interface DashboardProps {
    eventDates: { [key: string]: string };
    setSelectedEvent: any;
}

const TOP_SECTION_HEIGHT = '73px';

const DashboardHeader = (props: DashboardProps) => {
    const { eventDates, setSelectedEvent } = props;
    const appContext = useContext(AppContext);
    const { events, selectedEvent } = appContext;
    const [selectedDate, setSelectedDate] = useState<Date>(
        new Date(selectedEvent.startDate)
    );

    function handleChangeDate(date: Date) {
        const eventForNewDate = getEventForDate(date);
        if (eventForNewDate) {
            setSelectedEvent(eventForNewDate);
        }
        setSelectedDate(date);
    }

    function getEventForDate(date: Date): TallyEvent | undefined {
        const dateString = date.toDateString();

        for (const event of events) {
            if (eventDates[event.id] === dateString) {
                return event;
            }
        }
    }

    function getDateClassName(date: Date): string {
        if (date === selectedDate) {
            return 'selectedDate';
        } else {
            return getEventForDate(date) ? 'eventDate' : '';
        }
    }

    function renderTopRow() {
        const { shortName } = selectedEvent;

        return (
            <TopRowContainer>
                <DateInputContainer>
                    <DatePicker
                        className="portal-datepicker"
                        dateFormat="MMM d"
                        dayClassName={date => getDateClassName(date)}
                        onChange={handleChangeDate}
                        selected={selectedDate}
                        todayButton={shortName}
                    />
                    <DownIcon src={downArrow} />
                </DateInputContainer>
                <TallyLogo alt="Tally Logo" src={tallyLogo} />
            </TopRowContainer>
        );
    }

    function renderDataTiles() {
        const { playerCount, userInfo } = selectedEvent;
        const { newPlayerPercent, newPlayerEmailOptInPercent } = userInfo;
        // TODO: probably should only get these once and pass to this component
        // since it's partner-level data.
        const averagePlayersPerEvent = getAverageUsersPerEvent(events);
        const averageNewPlayerMarketingOptIn = getAverageNewPlayerMarketingOptIn(
            events
        );
        const averageNewPlayersPerEvent = getAverageNewUserPerEvent(events);
        return (
            <Grid container={true} spacing={5}>
                <Grid item={true} sm={6} md={4} xs={12}>
                    <PlayerTrend
                        playerCount={playerCount}
                        runningAverage={averagePlayersPerEvent}
                    />
                </Grid>
                <Grid item={true} sm={6} md={4} xs={12}>
                    <PlayerBreakdown
                        averageNewPlayersPerEvent={averageNewPlayersPerEvent}
                        newPlayerPercent={newPlayerPercent}
                    />
                </Grid>
                <Grid item={true} sm={6} md={4} xs={12}>
                    <EmailOptInStats
                        averageNewPlayerMarketingOptIn={
                            averageNewPlayerMarketingOptIn
                        }
                        newPlayerEmailOptInPercent={newPlayerEmailOptInPercent}
                    />
                </Grid>
            </Grid>
        );
    }

    return (
        <HeadingContainer>
            <WidthContainer>
                {renderTopRow()}
                <TitleWrapper>
                    <EventTitle>{selectedEvent.shortName}</EventTitle>
                    {!selectedEvent.finalized && (
                        <InProgressLabel>&nbsp;(Live Event)</InProgressLabel>
                    )}
                </TitleWrapper>
                {renderDataTiles()}
            </WidthContainer>
        </HeadingContainer>
    );
};

const HeadingContainer = styled.div`
    background-color: ${TColor.G1_BLACK};
    font-family: Graphik Web;
    display: flex;
    justify-content: center;
    padding: 0 ${DESKTOP_CONTAINER_PADDING} ${DESKTOP_CONTAINER_PADDING};
    @media (max-width: ${MOBILE_WIDTH}) {
        padding: 0 ${MOBILE_CONTAINER_PADDING} ${MOBILE_CONTAINER_PADDING};
    }
`;

const WidthContainer = styled.div`
    max-width: ${MAX_WIDTH};
    width: 100%;
`;

const TitleWrapper = styled.div`
    align-items: baseline;
    display: flex;
`;

const EventTitle = styled.h1`
    color: ${TColor.P1_WHITE};
    font-size: 70px;
    letter-spacing: -1.2px;
    text-transform: uppercase;
    margin-bottom: 70px;
    @media (max-width: ${MOBILE_WIDTH}) {
        font-size: 38px;
        margin-bottom: 30px;
        margin-top: 20px;
    }
`;

const InProgressLabel = styled.span`
    opacity: 0.5;
    color: ${TColor.P1_WHITE};
    font-size: 13px;
    font-weight: 600;
    letter-spacing: 1px;
    line-height: 18px;
    text-transform: uppercase;
`;

const TopRowContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

const DateInputContainer = styled.div`
    display: flex;
    align-items: center;
    cursor: pointer;
    border-bottom: 1px solid ${TColor.P3_TEAL};
    transition: all 300ms;
    :hover {
        padding: 0 20px;
        background-color: ${TColor.P3_TEAL};
    }
`;

const TallyLogo = styled.img`
    height: ${TOP_SECTION_HEIGHT};
    width: 44px;
    @media (max-width: ${MOBILE_WIDTH}) {
        display: none;
    }
`;

const DownIcon = styled.img`
    width: 1em;
    height: 1em;
`;

export default DashboardHeader;
