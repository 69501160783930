export interface Config {
    apiBaseUrl: string;
    auth0Audience: string;
    auth0ClientId: string;
    auth0Domain: string;
    defaultPartner: string;
}

const PROD_BASE_URL = 'https://partner-api.playtally.com/portal';
const DEV_BASE_URL = 'https://partner-api-devint.playtally.com/portal';
const DEV_AUTH0_CLIENT_ID = 'kxLXsNlSFOBChx29oJzuaSL7cPRPfTF9';
const DEV_AUTH0_DOMAIN = 'dev-j6q2dyb1.auth0.com';

let config: Config;
if (window.location.hostname === 'portal.playtally.com') {
    config = {
        apiBaseUrl: PROD_BASE_URL,
        auth0Audience: PROD_BASE_URL,
        auth0ClientId: DEV_AUTH0_CLIENT_ID,
        auth0Domain: DEV_AUTH0_DOMAIN,
        defaultPartner: 'rams',
    };
} else if (window.location.hostname === 'portal.devint.playtally.com') {
    config = {
        apiBaseUrl: DEV_BASE_URL,
        auth0Audience: DEV_BASE_URL,
        auth0ClientId: DEV_AUTH0_CLIENT_ID,
        auth0Domain: DEV_AUTH0_DOMAIN,
        defaultPartner: 'tallyansDev',
    };
} else {
    config = {
        apiBaseUrl: DEV_BASE_URL,
        auth0Audience: DEV_BASE_URL,
        auth0ClientId: DEV_AUTH0_CLIENT_ID,
        auth0Domain: DEV_AUTH0_DOMAIN,
        defaultPartner: 'tallyansDev',
    };
}

export default config;
