import React from 'react';
import { useFetch } from 'use-http';

import ApiContext from '../contexts/ApiContext';
import PartnerDashboard from './PartnerDashboard';
import {
    TallyUser,
    PredictionDetails,
    TallyEvent,
    TallyPrediction,
    TallyWinner,
    TallyPartner,
} from '../util/PortalDao';
import { SUPER_USER } from '../util/Auth0Config';
import config from '../config';

interface Props {
    authToken: string;
    partnerName: string;
}

const BASE_URL = config.apiBaseUrl;

function AuthorizedApp(props: Props) {
    const { authToken, partnerName } = props;

    const request = useFetch(BASE_URL, {
        headers: {
            Authorization: `Bearer ${authToken}`,
            Connection: 'keep-alive',
            'Content-Type': 'application/json',
            'accept-encoding': 'gzip, deflate',
        },
    });

    // TODO: All of these calls need to check response and return error object or desired object
    // So they'll eventually call the same function with their specific paths passed in as param

    async function getPartners(): Promise<TallyPartner[]> {
        if (partnerName !== SUPER_USER) {
            throw new Error(
                'Non-Super Users do not have access to this endpoint'
            );
        }
        return request.get('/partners');
    }

    async function getEventsForPartner(
        partnerId: string
    ): Promise<TallyEvent[]> {
        return request.get(`/partners/${partnerId}/events`);
    }

    async function getPredictionsForEvent(
        eventId: string
    ): Promise<TallyPrediction[]> {
        return request.get(`/events/${eventId}/predictions`);
    }

    async function getContendersForEvent(
        eventId: string
    ): Promise<TallyWinner[]> {
        return request.get(`/events/${eventId}/contenders`);
    }

    async function getUsers(eventId: string): Promise<TallyUser[]> {
        return request.get(`/events/${eventId}/users`);
    }

    async function getPredictionDetails(
        predictionId: string
    ): Promise<PredictionDetails[]> {
        return request.get(`/predictions/${predictionId}/details`);
    }

    function renderApp() {
        if (authToken) {
            return (
                <ApiContext.Provider
                    value={{
                        getContendersForEvent,
                        getEventsForPartner,
                        getPartners,
                        getPredictionDetails,
                        getPredictionsForEvent,
                        getUsers,
                        partnerName,
                    }}
                >
                    <PartnerDashboard />
                </ApiContext.Provider>
            );
        }
    }
    return <>{renderApp()}</>;
}

export default AuthorizedApp;
