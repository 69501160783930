import React from 'react';
import styled from '@emotion/styled';
import { TColor, MAX_WIDTH } from '../styles/StyleConstants';

interface Props {
    selectedSponsor: string;
    setSelectedSponsor: any;
    sponsorNames: string[];
}

const ALL = 'All';

const FilterBar = (props: Props) => {
    const { selectedSponsor, setSelectedSponsor, sponsorNames } = props;

    return (
        <Container>
            <WidthContainer>
                <SponsorLink
                    onClick={() => {
                        setSelectedSponsor(ALL);
                    }}
                    key={ALL}
                    active={ALL === selectedSponsor}
                >
                    {ALL}
                </SponsorLink>
                {sponsorNames.map((name: string) => (
                    <SponsorLink
                        onClick={() => {
                            setSelectedSponsor(name);
                        }}
                        key={name}
                        active={name === selectedSponsor}
                    >
                        {name}
                    </SponsorLink>
                ))}
            </WidthContainer>
        </Container>
    );
};

const Container = styled.div`
    display: flex;
    justify-content: center;
    background-color: ${TColor.P2_SEAFOAM};
    height: 82px;
    padding: 0 60px;
`;

const WidthContainer = styled.div`
    max-width: ${MAX_WIDTH};
    display: flex;
    align-items: center;
    width: 100%;
`;

const SponsorLink = styled.p<{ active: boolean }>`
    color: ${TColor.P1_WHITE};
    cursor: pointer;
    opacity: ${props => (props.active ? 1 : 0.5)};
    font-size: 24px;
    font-weight: 600;
    line-height: 18px;
    margin-right: 30px;
    transition: all 300ms;
    :hover {
        opacity: 1;
    }
`;

export default FilterBar;
