import React, { useEffect, useState } from 'react';
import { Route } from 'react-router-dom';
import { useAuth0 } from '../react-auth0-wrapper';
import { APP_METADATA, ROLES_TO_PARTNER_ID } from '../util/Auth0Config';

const PrivateRoute = ({
    component: Component,
    path,
    ...rest
}: {
    [key: string]: any;
}) => {
    const {
        isAuthenticated,
        getTokenSilently,
        loginWithRedirect,
        user,
    } = useAuth0();
    const [authToken, setAuthToken] = useState(undefined);

    useEffect(() => {
        const fn = async () => {
            if (isAuthenticated === false) {
                await loginWithRedirect({
                    appState: { targetUrl: path },
                });
            } else {
                const token = await getTokenSilently();
                setAuthToken(token);
            }
        };
        fn();
    }, [getTokenSilently, isAuthenticated, loginWithRedirect, path]);

    function render(props: any) {
        if (!user) {
            return null;
        }
        const role: string | undefined =
            user[APP_METADATA].authorization.roles[0];
        if (!role) {
            return (
                <p>
                    You are not authorized to use this application. Please
                    contact an admin.
                </p>
            );
        }
        const partnerName = ROLES_TO_PARTNER_ID[role];
        return authToken && isAuthenticated === true ? (
            <Component
                {...props}
                authToken={authToken}
                partnerName={partnerName}
            />
        ) : null;
    }

    return <Route path={path} render={render} {...rest} />;
};

export default PrivateRoute;
