import React from 'react';
import {
    PredictionDetails,
    TallyUser,
    TallyEvent,
    TallyPrediction,
    TallyWinner,
    TallyPartner,
} from '../util/PortalDao';

interface ApiContextProps {
    getContendersForEvent: (eventId: string) => Promise<TallyWinner[]>;
    getEventsForPartner: (partnerId: string) => Promise<TallyEvent[]>;
    getPartners: () => Promise<TallyPartner[]>;
    getPredictionDetails: (
        predictionId: string
    ) => Promise<PredictionDetails[]>;
    getPredictionsForEvent: (eventId: string) => Promise<TallyPrediction[]>;
    getUsers: (eventId: string) => Promise<TallyUser[]>;
    partnerName: string;
}

const dummy = () => {
    throw new Error(
        'Should never be called. Is ApiContext.Provider in the component tree?'
    );
};

const ApiContext = React.createContext<ApiContextProps>({
    getContendersForEvent: dummy,
    getEventsForPartner: dummy,
    getPartners: dummy,
    getPredictionDetails: dummy,
    getPredictionsForEvent: dummy,
    getUsers: dummy,
    partnerName: '',
});

export default ApiContext;
