import { parse } from 'json2csv';

import {
    TallyPrediction,
    TallyEvent,
    PredictionDetails,
    TallyOption,
    TallyUser,
    TallyWinner,
} from './PortalDao';
import { dateToMonthNameAndDayAndHour } from './DateUtils';

function getOptionPercentString(
    totalUserCount: number,
    optionCount: number
): string {
    const optionpercent = (optionCount / totalUserCount) * 100;
    const optionPercentString = `${optionpercent.toFixed(0)}%`;
    return optionPercentString;
}

// Formats prediction data for CSV
export function formatPredictionsForDownload(predictions: TallyPrediction[]) {
    const formattedPredictions: any = [];

    for (const prediction of predictions) {
        const isFitb = prediction.type === 'FILL_IN_THE_BLANK';
        const isPoll = prediction.type === 'POLL';
        const correctAnswer = prediction.correctAnswer;
        const totalUserCount = prediction.totalUsersCount;
        if (!prediction.options) {
            continue;
        }
        const firstOptionText = isFitb ? '—' : prediction.options[0].text;

        const formattedPrediction = {
            'Release Milestone': prediction.releaseMilestone,
            'Release Date': prediction.releasedDate,
            'Question Number': prediction.number,
            'Point Value': prediction.totalPointValue,
            'Player Count': totalUserCount,
            Type: prediction.type,
            Text: prediction.text,
            'Question Options': firstOptionText,
            'Correct Answer': isFitb
                ? prediction.correctAnswer
                : isPoll
                ? '—'
                : correctAnswer === firstOptionText
                ? 'Yes'
                : 'No',
            'Answer Count': prediction.options[0].count,
            'Answer %': isFitb
                ? '—'
                : getOptionPercentString(
                      totalUserCount,
                      prediction.options[0].count
                  ),
        };
        formattedPredictions.push(formattedPrediction);
        for (let i = 1; i < prediction.options.length; i++) {
            const optionRow = {
                'Release Milestone': ' ',
                'Release Date': ' ',
                'Question Number': ' ',
                'Point Value': ' ',
                'Player Count': ' ',
                Type: ' ',
                Text: ' ',
                'Question Options': prediction.options[i].text,
                'Correct Answer': isPoll
                    ? '—'
                    : correctAnswer === prediction.options[i].text
                    ? 'Yes'
                    : 'No',
                'Answer Count': prediction.options[i].count,
                'Answer %': isFitb
                    ? '—'
                    : getOptionPercentString(
                          totalUserCount,
                          prediction.options[i].count
                      ),
            };
            formattedPredictions.push(optionRow);
        }
    }
    return formattedPredictions;
}

// Format user data for a given prediction for download
// If sponsored prediction is true, it should only add
// users who got the question correct.
export function formatUserPredictionInfo(params: {
    eventId: string;
    prediction: TallyPrediction;
    details: PredictionDetails[];
    sponsoredPrediction: boolean;
}) {
    const { details, eventId, prediction, sponsoredPrediction } = params;
    const isFitb = prediction.type === 'FILL_IN_THE_BLANK';

    // create a map of option id -> option text
    const options: { [key: string]: string } = {};
    const formattedPredictionData: any = [];
    prediction.options.forEach((option: TallyOption) => {
        options[option.id] = option.text;
    });

    for (const detail of details) {
        const userAnswer = isFitb
            ? detail.userAnswer
            : options[detail.userAnswer];
        if (
            sponsoredPrediction &&
            (!prediction.correctAnswer ||
                userAnswer !== prediction.correctAnswer)
        ) {
            continue;
        }
        const userPredictionData: any = {
            Email: detail.email,
            'Display Name': detail.displayName,
            'User Answer': userAnswer,
            'First Time Player': isFirstTimePlayer(
                eventId,
                detail.firstEventId
            ),
            'Sign Up Date': new Date(detail.createdDate),
        };

        if (sponsoredPrediction && prediction.sponsorshipUnit) {
            userPredictionData.Prize =
                prediction.sponsorshipUnit.sponsoredPrize;
        }
        formattedPredictionData.push(userPredictionData);
    }

    return formattedPredictionData;
}

// Formats data when downloading all users for event
export function formatEventUserData(users: TallyUser[]) {
    const formattedUsers: any = [];
    users.forEach((user: TallyUser) => {
        const formattedUser = {
            Email: user.email,
            'Display Name': user.displayName,
            'Email Marketing Opt-In': user.emailMarketingOptIn,
            'SMS Marketing Opt-In': user.smsMarketingOptIn,
            'First Time Player': user.firstTimePlayer,
            'Sign Up Date': new Date(user.createdDate),
        };
        formattedUsers.push(formattedUser);
    });
    return formattedUsers;
}

// Format Event Winner Data
export function formatEventWinnerData(params: {
    eventId: string;
    prizes: string[];
    winners: TallyWinner[];
}) {
    const { eventId, prizes, winners } = params;
    const formattedWinners: any = [];
    winners.forEach((winner: TallyWinner, index: number) => {
        const formattedUser = {
            Rank: winner.rank,
            Prize: prizes[index],
            Email: winner.email,
            'Display Name': winner.displayName,
            'Phone Number': winner.phoneNumber,
            'First Time Player': isFirstTimePlayer(
                eventId,
                winner.firstEventId
            ),
            'Sign Up Date': new Date(winner.createdDate),
        };
        formattedWinners.push(formattedUser);
    });
    return formattedWinners;
}

// Gets info about predictions release time and release milestone
export const getPredictionDetailText = (
    prediction: TallyPrediction
): string => {
    const { releasedDate, releaseMilestone } = prediction;
    if (!releasedDate) {
        return '';
    }
    const milestone = releaseMilestone ? ` — ${releaseMilestone}` : '';
    const releaseDateObject = dateToMonthNameAndDayAndHour(
        new Date(releasedDate)
    );
    return `${releaseDateObject.hour} ${releaseDateObject.month} ${releaseDateObject.day} ${milestone}`;
};

export function getAverageUsersPerEvent(events: TallyEvent[]): number {
    const totalPlayers = events.reduce((accumulator, currentValue) => {
        return accumulator + currentValue.playerCount;
    }, 0);

    return totalPlayers / events.length;
}

export function getAverageNewPlayerMarketingOptIn(
    events: TallyEvent[]
): number {
    const totalPercentPoints = events.reduce((accumulator, currentValue) => {
        return accumulator + currentValue.userInfo.newPlayerEmailOptInPercent;
    }, 0);

    return totalPercentPoints / events.length;
}

// TODO: Currently doesn't handle first game being 100% new players
export function getAverageNewUserPerEvent(events: TallyEvent[]): number {
    const totalNewPlayerPercent = events.reduce((accumulator, currentValue) => {
        return accumulator + currentValue.userInfo.newPlayerPercent;
    }, 0);

    return totalNewPlayerPercent / events.length;
}

// takes a number and spits out how you'd say it as a rank/place
// e.g 1 -> 1st, 3 -> 3rd, etc.
// Does not handle 20 and up.
export function numberToStringPlace(num: number): string {
    switch (num) {
        case 1:
            return `${num}st`;
        case 2:
            return `${num}nd`;
        case 3:
            return `${num}rd`;
        default: {
            return `${num}th`;
        }
    }
}

// TODO: Add headers so browsers don't block the download
export function downloadCSV(data: any, filename: string) {
    try {
        const contentType = 'text/csv';
        const dataAsCsv = parse(data);
        const a = document.createElement('a');
        a.download = filename;
        a.href = 'data:' + contentType + ',' + dataAsCsv;
        a.target = '_blank';
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    } catch (e) {
        console.error('error downloading data as CSV', e);
    }
}

export const numberWithCommas = (x: number): string => {
    if (x === undefined) {
        return 'N/A';
    }
    const parts = x.toString().split('.');
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    return parts.join('.');
};

function isFirstTimePlayer(eventId: string, firstEventId?: string) {
    return !firstEventId || eventId === firstEventId;
}
