import React from 'react';
import styled from '@emotion/styled';
import { BrowserRouter, Switch } from 'react-router-dom';

import { useAuth0 } from './react-auth0-wrapper';
import NavBar from './components/Navbar';
import PrivateRoute from './components/PrivateRoute';
import AuthorizedApp from './components/AuthorizedApp';
import './styles/global.scss';

function App() {
    const { isAuthenticated } = useAuth0();

    return (
        <BrowserRouter>
            {!isAuthenticated && <NavBar />}
            <PortalBody>
                <Switch>
                    <PrivateRoute
                        component={AuthorizedApp}
                        exact={true}
                        path="/"
                    />
                </Switch>
            </PortalBody>
        </BrowserRouter>
    );
}

export default App;

const PortalBody = styled.div`
    font-family: Graphik Web";
`;
