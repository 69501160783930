import React, { useContext } from 'react';
import styled from '@emotion/styled';
import { CSVLink } from 'react-csv';

import {
    TColor,
    MAX_WIDTH,
    DESKTOP_CONTAINER_PADDING,
    MOBILE_WIDTH,
    MOBILE_CONTAINER_PADDING,
} from '../styles/StyleConstants';
import PortalButton, { IconType, BUTTON_DEFAULT_STYLES } from './PortalButton';
import { TallyPrediction } from '../util/PortalDao';
import PollCard from './PollCard';
import { formatPredictionsForDownload } from '../util/DataHelpers';
import AppContext from '../contexts/AppContext';

interface Props {
    polls: TallyPrediction[];
}

const PollSection = (props: Props) => {
    const { polls } = props;
    const { selectedEvent } = useContext(AppContext);
    const { shortName, playerCount } = selectedEvent;

    function renderPollSection() {
        if (polls.length === 0) {
            return null;
        }
        return (
            <Container>
                <WidthContainer>
                    <HeaderArea>
                        <ComponentTitle>Polls</ComponentTitle>
                        <CSVLink
                            data={formatPredictionsForDownload(polls)}
                            filename={`${shortName}_polls.csv`}
                            className="btn btn-primary"
                            target="_blank"
                        >
                            <PortalButton
                                buttonStyle={BUTTON_DEFAULT_STYLES}
                                iconType={IconType.DOWNLOAD}
                                text="Summary"
                            />
                        </CSVLink>
                    </HeaderArea>
                    <CardsContainer>
                        {polls.map((poll: TallyPrediction) => (
                            <PollCard
                                key={poll.id}
                                eventUserCount={playerCount}
                                poll={poll}
                            />
                        ))}
                    </CardsContainer>
                </WidthContainer>
            </Container>
        );
    }

    return renderPollSection();
};

const Container = styled.div`
    background-color: ${TColor.G3_OFF_WHITE};
    display: flex;
    justify-content: center;
    padding: ${DESKTOP_CONTAINER_PADDING};
    @media (max-width: ${MOBILE_WIDTH}) {
        padding: ${MOBILE_CONTAINER_PADDING};
    }
`;

const WidthContainer = styled.div`
    max-width: ${MAX_WIDTH};
    width: 100%;
`;

const HeaderArea = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
`;

const ComponentTitle = styled.h3`
    color: ${TColor.G1_BLACK};
    font-size: 24px;
    font-weight: 600;
    line-height: 18px;
`;

const CardsContainer = styled.div`
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
`;

export default PollSection;
