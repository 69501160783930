import React, { useContext, useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { makeStyles } from '@material-ui/core/styles';
import { TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';

import PortalButton, { IconType, BUTTON_DEFAULT_STYLES } from './PortalButton';
import {
    TColor,
    MAX_WIDTH,
    MOBILE_WIDTH,
    DESKTOP_CONTAINER_PADDING,
    MOBILE_CONTAINER_PADDING,
} from '../styles/StyleConstants';
import { TallyWinner } from '../util/PortalDao';
import {
    numberToStringPlace,
    formatEventWinnerData,
    downloadCSV,
} from '../util/DataHelpers';
import AppContext from '../contexts/AppContext';

interface Props {
    winners: TallyWinner[];
}

const WinnerSection = (props: Props) => {
    const { selectedEvent } = useContext(AppContext);
    const { prizes } = selectedEvent;
    const { winners } = props;
    const [fadedOutTable, setFadedOutTable] = useState(false);
    const classes = useStyles();

    useEffect(() => {
        setFadedOutTable(true);
    }, [selectedEvent]);

    useEffect(() => {
        setFadedOutTable(false);
    }, [winners]);

    if (!prizes) {
        return null;
    }

    function handleButtonClick() {
        if (!prizes) {
            return null;
        }
        const { id: eventId, shortName } = selectedEvent;
        const formattedData = formatEventWinnerData({
            eventId,
            prizes,
            winners,
        });
        const filename = `${shortName}_winners.csv`;
        downloadCSV(formattedData, filename);
    }

    function renderTitle() {
        return (
            <TitleArea>
                <ComponentTitle>
                    {selectedEvent.finalized
                        ? 'Game Winners'
                        : 'Prize Contenders (pending)'}
                </ComponentTitle>
                <PortalButton
                    buttonStyle={BUTTON_DEFAULT_STYLES}
                    clickHandler={handleButtonClick}
                    iconType={IconType.DOWNLOAD}
                    text="All"
                />
            </TitleArea>
        );
    }

    function renderMobileView() {
        if (!prizes) {
            return null;
        }
        return (
            <MobileContainer>
                {winners.map((winner: TallyWinner, index: number) => (
                    <MobileWinnerContainer key={index}>
                        <AwardText>
                            {numberToStringPlace(winner.rank)} PLACE
                        </AwardText>
                        <PrizeText>{prizes[index]}</PrizeText>
                        <DefaultText>{winner.displayName}</DefaultText>
                        <DefaultText>{winner.phoneNumber}</DefaultText>
                        <DefaultText>{winner.email}</DefaultText>
                    </MobileWinnerContainer>
                ))}
            </MobileContainer>
        );
    }

    return (
        <Container>
            <WidthContainer>
                {renderTitle()}
                {renderMobileView()}
                <PTable style={{ opacity: fadedOutTable ? 0.5 : 1 }}>
                    <TableHead>
                        <TableRow>
                            <TableCell className={classes.tableCell}>
                                <TableHeader>AWARD</TableHeader>
                            </TableCell>
                            <TableCell className={classes.prizeCell}>
                                <TableHeader>PRIZE</TableHeader>
                            </TableCell>
                            <TableCell className={classes.tableCell}>
                                <TableHeader>RECIPIENT</TableHeader>
                            </TableCell>
                            <TableCell className={classes.tableCell}>
                                <TableHeader>PHONE NUMBER</TableHeader>
                            </TableCell>
                            <TableCell className={classes.tableCell}>
                                <TableHeader>EMAIL</TableHeader>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {winners.map((winner: TallyWinner, index: number) => (
                            <TableRow
                                className={classes.tableRow}
                                key={winner.displayName}
                            >
                                <TableCell className={classes.tableCell}>
                                    <AwardText>
                                        {numberToStringPlace(winner.rank)} PLACE
                                    </AwardText>
                                </TableCell>
                                <TableCell className={classes.prizeCell}>
                                    <PrizeText>{prizes[index]}</PrizeText>
                                </TableCell>
                                <TableCell className={classes.tableCell}>
                                    <DefaultText>
                                        {winner.displayName}
                                    </DefaultText>
                                </TableCell>
                                <TableCell className={classes.tableCell}>
                                    <DefaultText>
                                        {winner.phoneNumber}
                                    </DefaultText>
                                </TableCell>
                                <TableCell className={classes.tableCell}>
                                    <DefaultText>{winner.email}</DefaultText>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </PTable>
            </WidthContainer>
        </Container>
    );
};

const Container = styled.div`
    background-color: ${TColor.P1_WHITE};
    display: flex;
    justify-content: center;
    padding: ${DESKTOP_CONTAINER_PADDING};
    @media (max-width: ${MOBILE_WIDTH}) {
        padding: ${MOBILE_CONTAINER_PADDING};
    }
`;

const WidthContainer = styled.div`
    max-width: ${MAX_WIDTH};
    width: 100%;
`;

const TitleArea = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
`;

const ComponentTitle = styled.h3`
    color: ${TColor.G1_BLACK};
    font-size: 24px;
    font-weight: 600;
    line-height: 18px;
`;

const PTable = styled.table`
    border-collapse: separate;
    width: 100%;
    @media (max-width: ${MOBILE_WIDTH}) {
        display: none;
    }
`;

const MobileContainer = styled.div`
    display: none;
    @media (max-width: ${MOBILE_WIDTH}) {
        display: flex;
        flex-direction: column;
    }
`;

const MobileWinnerContainer = styled.div`
    border-bottom: 1px solid ${TColor.G4_LIGHT_GRAY};
    display: flex;
    flex-direction: column;
    padding-bottom: 18px;
    padding-top: 15px;
`;

const TableHeader = styled.p`
    opacity: 0.5;
    color: ${TColor.G1_BLACK};
    font-size: 13px;
    font-family: Graphik Web;
    font-weight: 600;
    letter-spacing: 1px;
    line-height: 18px;
    text-align: left;
`;

const AwardText = styled.p`
    color: ${TColor.G1_BLACK};
    font-size: 13px;
    font-weight: 600;
    letter-spacing: 1px;
    line-height: 18px;
    text-transform: uppercase;
`;

const PrizeText = styled.p`
    color: ${TColor.G1_BLACK};
    font-size: 16px;
    line-height: 22px;
`;

const DefaultText = styled.p`
    color: ${TColor.P2_SEAFOAM};
    font-size: 16px;
    line-height: 22px;
`;

const useStyles = makeStyles(theme => ({
    prizeCell: {
        borderBottom: '1px solid #EFEFEF',
        maxWidth: '340px',
        paddingLeft: '0',
        verticalAlign: 'top',
        width: '40%',
    },
    table: {
        minWidth: 650,
    },
    tableCell: {
        borderBottom: '1px solid #EFEFEF',
        paddingLeft: '0',
        verticalAlign: 'top',
        width: '20%',
    },
    tableRow: {
        minHeight: '64px',
    },
}));

export default WinnerSection;
