export const APP_METADATA = 'https://portal.playtally.com/app_metadata';

// ROLES
export const SUPER_USER = 'Super User';
const BLUES_ID = 'stlouisblues';
const RAMS_ID = 'rams';
const WILD_ID = 'wild2020';

export const ROLES_TO_PARTNER_ID: { [key: string]: string } = {
    'Blues User': BLUES_ID,
    'Super User': SUPER_USER,
    'Rams User': RAMS_ID,
    'Wild User': WILD_ID,
};

export const PARTNER_ID_TO_GAME_NAME: { [key: string]: string } = {
    stlouisblues: 'Enter the Zone',
    rams: "Rams Pick'em",
    wild2020: "Wild Predictions",
};
