import { TallyEvent } from '../util/PortalDao';

import React from 'react';

interface AppContextProps {
    events: TallyEvent[];
    partnerId: string;
    selectedEvent: TallyEvent;
}

const dummy = { dummy: 'dummy' };

const AppContext = React.createContext<AppContextProps>({
    events: (dummy as any) as TallyEvent[],
    partnerId: 'NA',
    selectedEvent: (dummy as unknown) as TallyEvent,
});

export default AppContext;
