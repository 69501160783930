import React from 'react';
import { TColor } from '../../styles/StyleConstants';
import styled from '@emotion/styled';
import ProgressBar, { DEFAULT_AVG_BAR } from './ProgressBar';

interface Props {
    averageNewPlayersPerEvent: number;
    newPlayerPercent: number;
}

const PlayerBreakdown = (props: Props) => {
    const { averageNewPlayersPerEvent, newPlayerPercent } = props;

    function renderData() {
        const roundedNewPlayerPercent = Math.round(newPlayerPercent * 100);
        const newPlayerPercentString = `${roundedNewPlayerPercent}%`;
        const returningPlayerPercentString = `${100 -
            roundedNewPlayerPercent}%`;

        const roundedNewPlayerPerEventPercent = Math.round(
            averageNewPlayersPerEvent * 100
        );

        const averageReturningPlayerPercent =
            100 - roundedNewPlayerPerEventPercent;

        const averageReturningBarProps = {
            ...DEFAULT_AVG_BAR,
            label: `${averageReturningPlayerPercent}% AVG`,
            percent: averageReturningPlayerPercent,
        };
        const averageNewBarProps = {
            ...DEFAULT_AVG_BAR,
            label: `${roundedNewPlayerPerEventPercent}% AVG`,
            percent: roundedNewPlayerPerEventPercent,
        };
        return (
            <DataSection>
                <BarLabel>{newPlayerPercentString} New</BarLabel>
                <ProgressBar
                    averageBarProps={averageNewBarProps}
                    bgColor={TColor.P6_DARK_SEAFOAM}
                    fgColor={TColor.P4_LIGHT_BLUE}
                    pixelHeight={5}
                    percentFull={newPlayerPercentString}
                    width={'100%'}
                />
                <BarLabel topSpace={'45px'}>
                    {returningPlayerPercentString} Returning
                </BarLabel>
                <ProgressBar
                    averageBarProps={averageReturningBarProps}
                    bgColor={TColor.P6_DARK_SEAFOAM}
                    fgColor={TColor.P4_LIGHT_BLUE}
                    pixelHeight={5}
                    percentFull={returningPlayerPercentString}
                    width={'100%'}
                />
            </DataSection>
        );
    }

    return (
        <Container>
            <TopSection>
                <Title>Player Breakdown</Title>
            </TopSection>
            {renderData()}
        </Container>
    );
};

const Container = styled.div`
    text-align: left;
`;
const TopSection = styled.div`
    border-bottom: 1px solid ${TColor.G5_GRAY};
    width: 100%;
    margin-bottom: 28px;
`;

const Title = styled.div`
    opacity: 0.5;
    color: ${TColor.P1_WHITE};
    font-size: 13px;
    font-weight: 600;
    letter-spacing: 1px;
    line-height: 18px;
    text-transform: uppercase;
    margin-bottom: 3px;
`;

const DataSection = styled.div`
    width: 100%;
`;

const BarLabel = styled.h5<{ topSpace?: string }>`
    color: ${TColor.P4_LIGHT_BLUE};
    font-size: 24px;
    font-weight: 600;
    line-height: 18px;
    margin-bottom: 15px;
    margin-top: ${props => props.topSpace};
`;
export default PlayerBreakdown;
