import React from 'react';
import { TColor } from '../../styles/StyleConstants';
import styled from '@emotion/styled';

interface Props {
    averageNewPlayerMarketingOptIn: number;
    newPlayerEmailOptInPercent: number;
}

const EmailOptInStats = (props: Props) => {
    const {
        averageNewPlayerMarketingOptIn,
        newPlayerEmailOptInPercent,
    } = props;

    function renderData() {
        const roundedNewPlayerPercent = Math.round(
            newPlayerEmailOptInPercent * 100
        );
        const roundedAverageOptInPercent = Math.round(
            averageNewPlayerMarketingOptIn * 100
        );

        const newPlayerPercentString = `${roundedNewPlayerPercent}% Email Opt In`;
        const averagePercentString = `${roundedAverageOptInPercent}% AVG`;
        return (
            <DataSection>
                <MainLabel>{newPlayerPercentString}</MainLabel>
                <SubtitleLabel>{averagePercentString}</SubtitleLabel>
            </DataSection>
        );
    }

    return (
        <Container>
            <TopSection>
                <Title>New Player Email Opt In</Title>
            </TopSection>
            {renderData()}
        </Container>
    );
};

const Container = styled.div`
    text-align: left;
`;

const TopSection = styled.div`
    border-bottom: 1px solid ${TColor.G5_GRAY};
    width: 100%;
    margin-bottom: 28px;
`;

const Title = styled.div`
    opacity: 0.5;
    color: ${TColor.P1_WHITE};
    font-size: 13px;
    font-weight: 600;
    letter-spacing: 1px;
    line-height: 18px;
    text-transform: uppercase;
    margin-bottom: 3px;
`;

const DataSection = styled.div`
    width: 100%;
`;

const MainLabel = styled.h5<{ topSpace?: string }>`
    color: ${TColor.P4_LIGHT_BLUE};
    font-size: 24px;
    font-weight: 600;
    line-height: 18px;
    margin-bottom: 8px;
    margin-top: ${props => props.topSpace};
`;

const SubtitleLabel = styled.p`
    opacity: 0.7;
    color: ${TColor.P1_WHITE};
    font-size: 12px;
    font-style: italic;
    letter-spacing: 1.29px;
    line-height: 18px;
    margin-bottom: 15px;
`;
export default EmailOptInStats;
