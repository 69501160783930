import React, { useEffect, useContext, useState } from 'react';
import styled from '@emotion/styled';
import { TColor, MAX_WIDTH } from '../styles/StyleConstants';
import ApiContext from '../contexts/ApiContext';
import { TallyPartner } from '../util/PortalDao';

interface Props {
    partnerChanged: (partnerId: string) => void;
}

const AdminFooter = (props: Props) => {
    const { partnerChanged } = props;
    const { getPartners } = useContext(ApiContext);
    const [partners, setPartners] = useState<TallyPartner[]>();

    useEffect(() => {
        // get partners so we can list them and elt admin users choose which partner to view
        const fetchPartners = async () => {
            const partnersResponse = await getPartners();
            setPartners(partnersResponse);
        };
        fetchPartners();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    function handleClick(partnerId: string) {
        partnerChanged(partnerId);
    }

    function renderPartners() {
        if (!partners || partners.length === 0) {
            return null;
        }
        return (
            <LinkContainer>
                {partners.map((partner: TallyPartner) => (
                    <PartnerLink
                        key={partner.id}
                        onClick={() => handleClick(partner.id)}
                    >
                        {partner.name}
                    </PartnerLink>
                ))}
            </LinkContainer>
        );
    }

    return (
        <Container>
            <WidthContainer>{renderPartners()}</WidthContainer>
        </Container>
    );
};

const Container = styled.div`
    background-color: ${TColor.G1_BLACK};
    padding: 60px 60px 0;
    display: flex;
    justify-content: center;
    padding-bottom: 55px; //temporary
`;

const WidthContainer = styled.div`
    max-width: ${MAX_WIDTH};
    width: 100%;
    display: flex;
    justify-content: center;
`;

const LinkContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    justify-content: space-evenly;
`;

const PartnerLink = styled.p`
    opacity: 0.5;
    color: ${TColor.P1_WHITE};
    font-size: 13px;
    font-weight: 600;
    letter-spacing: 1px;
    line-height: 18px;
    cursor: pointer;
    text-transform: uppercase;
    transition: all 300ms;
    :hover {
        opacity: 1;
    }
`;

export default AdminFooter;
