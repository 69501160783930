export enum TColor {
    // Primary
    P1_WHITE = '#ffffff',
    P2_SEAFOAM = '#015863',
    P3_TEAL = '#16BABE',
    P4_LIGHT_BLUE = '#D4F8FF',
    P5_ULTRA_LIGHT_BLUE = '#F3FDFF',
    P6_DARK_SEAFOAM = '#00343A',

    G1_BLACK = '#000000',
    G2_CHARCOAL = '#141414',
    G3_OFF_WHITE = '#FAFAFA',
    G4_LIGHT_GRAY = '#EFEFEF',
    G5_GRAY = '#383838',
}

export const MAX_WIDTH = '1024px';
export const MOBILE_WIDTH = '520px';
export const DESKTOP_CONTAINER_PADDING = '60px';
export const MOBILE_CONTAINER_PADDING = '20px';
