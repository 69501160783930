import React, { useContext } from 'react';
import styled from '@emotion/styled';
import { makeStyles } from '@material-ui/core/styles';
import { TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';

import PortalButton, { IconType, ButtonType } from './PortalButton';
import {
    TColor,
    MAX_WIDTH,
    MOBILE_WIDTH,
    DESKTOP_CONTAINER_PADDING,
    MOBILE_CONTAINER_PADDING,
} from '../styles/StyleConstants';
import { TallyPrediction } from '../util/PortalDao';
import {
    numberWithCommas,
    downloadCSV,
    formatUserPredictionInfo,
} from '../util/DataHelpers';
import AppContext from '../contexts/AppContext';
import ApiContext from '../contexts/ApiContext';

interface Props {
    predictions: TallyPrediction[];
}

const DOWNLOAD_BTN_STYLE = {
    bgColor: TColor.P1_WHITE,
    hoverBgColor: TColor.P3_TEAL,
    hoverTextColor: TColor.P1_WHITE,
    textColor: TColor.P3_TEAL,
};

const CUSTOM_DOWNLOAD_BUTTON_STYLE = 'position:absolute;right:-45px;';

const SponsorWinners = (props: Props) => {
    const { predictions } = props;
    const classes = useStyles();
    const { selectedEvent } = useContext(AppContext);
    const { getPredictionDetails } = useContext(ApiContext);

    if (predictions.length === 0) {
        return null;
    }

    async function downloadPrizeWinners(prediction: TallyPrediction) {
        const { sponsorshipUnit } = prediction;
        if (!sponsorshipUnit) {
            return;
        }
        const { id: eventId } = selectedEvent;
        const predictionDetails = await getPredictionDetails(prediction.id);
        const formattedData = formatUserPredictionInfo({
            eventId,
            prediction,
            details: predictionDetails,
            sponsoredPrediction: true,
        });
        const filename = `${sponsorshipUnit.sponsorName}sponsored_question_details.csv`;
        downloadCSV(formattedData, filename);
    }

    function renderTitle() {
        return (
            <TitleArea>
                <ComponentTitle>Sponsor Winners</ComponentTitle>
            </TitleArea>
        );
    }

    function renderPredictionRow(prediction: TallyPrediction) {
        const { sponsorshipUnit } = prediction;
        if (
            !sponsorshipUnit ||
            !prediction.correctAnswerCount ||
            !sponsorshipUnit.sponsoredPrize
        ) {
            return null;
        }

        return (
            <TableRow className={classes.tableRow} key={prediction.id}>
                <TableCell className={classes.tableCell}>
                    <SponsorName>{sponsorshipUnit.sponsorName}</SponsorName>
                </TableCell>
                <TableCell className={classes.prizeCell}>
                    <PrizeText>{sponsorshipUnit.sponsoredPrize}</PrizeText>
                    <SponsorContainer>
                        <SponsorDetailText>
                            Sponsored by {sponsorshipUnit.sponsorName}
                        </SponsorDetailText>
                        <SponsorImage
                            alt={`${sponsorshipUnit.sponsorName} logo`}
                            src={sponsorshipUnit.sponsorLogoUrl}
                        />
                    </SponsorContainer>
                </TableCell>
                <TableCell
                    className={classes.tableCell}
                    style={{ paddingRight: 0 }}
                >
                    <FinalColumnButtonContainer>
                        <DefaultText>
                            {numberWithCommas(prediction.correctAnswerCount)}{' '}
                            Winners
                        </DefaultText>
                        <PortalButton
                            buttonStyle={DOWNLOAD_BTN_STYLE}
                            buttonType={ButtonType.ICON}
                            clickHandler={() =>
                                downloadPrizeWinners(prediction)
                            }
                            customStyle={CUSTOM_DOWNLOAD_BUTTON_STYLE}
                            iconType={IconType.DOWNLOAD}
                            text=""
                        />
                    </FinalColumnButtonContainer>
                </TableCell>
            </TableRow>
        );
    }

    return (
        <Container>
            <WidthContainer>
                {renderTitle()}
                <PTable>
                    <TableHead>
                        <TableRow>
                            <TableCell className={classes.tableCell}>
                                <TableHeader>Sponsor</TableHeader>
                            </TableCell>
                            <TableCell className={classes.prizeCell}>
                                <TableHeader>Prize</TableHeader>
                            </TableCell>
                            <TableCell className={classes.tableCell}>
                                <TableHeader>Recipients</TableHeader>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {predictions.map((prediction: TallyPrediction) =>
                            renderPredictionRow(prediction)
                        )}
                    </TableBody>
                </PTable>
            </WidthContainer>
        </Container>
    );
};

const Container = styled.div`
    background-color: ${TColor.P1_WHITE};
    display: flex;
    justify-content: center;
    padding: ${DESKTOP_CONTAINER_PADDING};
    @media (max-width: ${MOBILE_WIDTH}) {
        padding: ${MOBILE_CONTAINER_PADDING};
    }
`;

const WidthContainer = styled.div`
    max-width: ${MAX_WIDTH};
    width: 100%;
`;

const TitleArea = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
`;

const ComponentTitle = styled.h3`
    color: ${TColor.G1_BLACK};
    font-size: 24px;
    font-weight: 600;
    line-height: 18px;
`;

const PTable = styled.table`
    border-collapse: separate;
    width: 100%;
`;

const TableHeader = styled.p`
    opacity: 0.5;
    color: ${TColor.G1_BLACK};
    font-size: 13px;
    font-family: Graphik Web;
    font-weight: 600;
    letter-spacing: 1px;
    line-height: 18px;
    text-align: left;
    text-transform: uppercase;
`;

const SponsorName = styled.p`
    color: ${TColor.G1_BLACK};
    font-size: 13px;
    font-weight: 600;
    letter-spacing: 1px;
    line-height: 18px;
    text-transform: uppercase;
`;

const SponsorImage = styled.img`
    margin-left: 5px;
    max-height: 50px;
    max-width: 150px;
`;

const SponsorContainer = styled.div`
    align-items: center;
    display: flex;
`;

const PrizeText = styled.p`
    color: ${TColor.G1_BLACK};
    font-size: 16px;
    line-height: 22px;
`;

const FinalColumnButtonContainer = styled.div`
    align-items: center;
    display: flex;
    position: relative;
`;

const SponsorDetailText = styled.p`
    opacity: 0.5;
    color: ${TColor.G1_BLACK};
    font-size: 12px;
    font-style: italic;
    letter-spacing: 1.29px;
    line-height: 18px;
    text-transform: uppercase;
`;

const DefaultText = styled.p`
    color: ${TColor.P2_SEAFOAM};
    font-size: 16px;
    line-height: 22px;
`;

const useStyles = makeStyles(theme => ({
    prizeCell: {
        borderBottom: `1px solid ${TColor.G4_LIGHT_GRAY}`,
        maxWidth: '340px',
        paddingLeft: '0',
        verticalAlign: 'top',
        width: '60%',
    },
    table: {
        minWidth: 650,
    },
    tableCell: {
        borderBottom: `1px solid ${TColor.G4_LIGHT_GRAY}`,
        paddingLeft: '0',
        verticalAlign: 'top',
        width: '20%',
    },
    tableRow: {
        minHeight: '64px',
    },
}));

export default SponsorWinners;
