import React, { useContext } from 'react';
import { useAuth0 } from '../react-auth0-wrapper';
import {
    DESKTOP_CONTAINER_PADDING,
    TColor,
    MAX_WIDTH,
    MOBILE_CONTAINER_PADDING,
    MOBILE_WIDTH,
} from '../styles/StyleConstants';
import styled from '@emotion/styled';
import AppContext from '../contexts/AppContext';
import { PARTNER_ID_TO_GAME_NAME } from '../util/Auth0Config';

const NavBar = () => {
    const { isAuthenticated, loginWithRedirect, logout } = useAuth0();
    const { partnerId } = useContext(AppContext);

    function getGameName(): string {
        const gameName = PARTNER_ID_TO_GAME_NAME[partnerId];
        return gameName ? gameName : partnerId;
    }
    return (
        <Container>
            <WidthContainer>
                {!isAuthenticated && (
                    <LogInOutButton onClick={() => loginWithRedirect({})}>
                        Log in
                    </LogInOutButton>
                )}
                {isAuthenticated && (
                    <>
                        <GameName>{getGameName()}</GameName>
                        <LogInOutButton onClick={() => logout()}>
                            LOG OUT
                        </LogInOutButton>
                    </>
                )}
            </WidthContainer>
        </Container>
    );
};

const Container = styled.header`
    background-color: ${TColor.G1_BLACK};
    display: flex;
    justify-content: center;
    height: 74px;
    margin-bottom: 40px;
    padding: 0 ${DESKTOP_CONTAINER_PADDING};
    @media (max-width: ${MOBILE_WIDTH}) {
        padding: 0 ${MOBILE_CONTAINER_PADDING};
        margin-bottom: 20px;
    }
`;

const WidthContainer = styled.div`
    max-width: ${MAX_WIDTH};
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    border-bottom: 1px solid ${TColor.G5_GRAY};
`;

const GameName = styled.h2`
    color: ${TColor.P1_WHITE};
    font-size: 13px;
    font-weight: 600;
    letter-spacing: 1.4px;
    line-height: 18px;
    text-transform: uppercase;
`;

const LogInOutButton = styled.button`
    background: none;
    border: none;
    color: ${TColor.P1_WHITE};
    cursor: pointer;
    text-decoration: none;
    font-size: 13px;
    font-weight: 600;
    letter-spacing: 1.4px;
    line-height: 18px;
    opacity: 0.5;
    transition: all 300ms;
    :hover {
        opacity: 1;
    }
`;

export default NavBar;
