import React, { useEffect, useState, useContext } from 'react';
import LoadingOverlay from 'react-loading-overlay';

import { TallyEvent, TallyPrediction, TallyWinner } from '../util/PortalDao';
import DashboardHeader from './DashboardHeader';
import { buildDateDictionary } from '../util/DateUtils';
import WinnerSection from './WinnerSection';
import PollSection from './PollSection';
import PredictionTable from './PredictionTable';
import SponsorWinners from './SponsorWinners';
import FilterBar from './FilterBar';
import AppContext from '../contexts/AppContext';
import ApiContext from '../contexts/ApiContext';
import { SUPER_USER } from '../util/Auth0Config';
import AdminFooter from './AdminFooter';
import NavBar from './Navbar';
import config from '../config';

// Used so we can ignore events from these iterations since they ruin cumulative data
const TEST_ITERATION_NAMES = ['ALPHA', 'BETA', 'Launch Test'];

const PartnerDashboard = () => {
    const [events, setEvents] = useState<TallyEvent[]>();
    const [selectedEvent, setSelectedEvent] = useState<TallyEvent>();
    const [predictions, setPredictionsForEvent] = useState<TallyPrediction[]>();
    const [contenders, setContendersForEvent] = useState<TallyWinner[]>();
    const [eventSponsors, setEventSponsors] = useState<string[]>();
    const [selectedSponsor, setSelectedSponsor] = useState<string>('All');
    const [superUserPartner, setSuperUserPartner] = useState<string>(
        config.defaultPartner
    );
    const apiContext = useContext(ApiContext);
    const { partnerName } = apiContext;
    const [loadingEvent, setLoadingEvent] = useState<boolean>();

    function superUserPartnerChanged(partnerId: string) {
        if (partnerName !== SUPER_USER) {
            throw new Error('This should not be possible');
        }
        setSuperUserPartner(partnerId);
    }

    useEffect(() => {
        const fetchEvents = async () => {
            const partner =
                partnerName === SUPER_USER ? superUserPartner : partnerName;
            const eventsResponse = await apiContext.getEventsForPartner(
                partner
            );
            if (!eventsResponse) {
                console.error('no event response. Handle me');
            } else {
                const playedEvents = eventsResponse.filter(
                    (event: TallyEvent) => {
                        return (
                            event.startDate &&
                            !TEST_ITERATION_NAMES.includes(event.iterationName)
                        );
                    }
                );
                setEvents(playedEvents);
                setSelectedEvent(playedEvents[0]);
            }
        };
        fetchEvents();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [superUserPartner]);

    useEffect(() => {
        if (!selectedEvent) {
            return;
        }
        const getEventDetails = async () => {
            setLoadingEvent(true);
            const predictionsResponse = await apiContext.getPredictionsForEvent(
                selectedEvent.id
            );
            const contendersResponse = await apiContext.getContendersForEvent(
                selectedEvent.id
            );

            const releasedPredictions = predictionsResponse.filter(
                (prediction: TallyPrediction) => prediction.visible
            );
            setPredictionsForEvent(releasedPredictions);
            setContendersForEvent(contendersResponse);
            setEventSponsors(getSponsorsForEvent(predictionsResponse));
            setTimeout(() => {
                setLoadingEvent(false);
            }, 400);
        };
        getEventDetails();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedEvent]);

    function renderDashboardHeader() {
        if (!events || events.length === 0 || !selectedEvent) {
            return 'No Events for partner. What can we show them?';
        }

        const eventDates = buildDateDictionary(events);

        return (
            <LoadingOverlay
                active={loadingEvent}
                spinner={true}
                styles={{
                    overlay: (base: any) => ({
                        ...base,
                        background: 'rgba(0, 0, 0, 0.9)',
                    }),
                }}
                text={`Loading ${selectedEvent.shortName}...`}
            >
                <DashboardHeader
                    eventDates={eventDates}
                    setSelectedEvent={setSelectedEvent}
                />
            </LoadingOverlay>
        );
    }

    function renderFilterBar() {
        if (eventSponsors === undefined) {
            return;
        }
        return (
            <FilterBar
                selectedSponsor={selectedSponsor}
                setSelectedSponsor={setSelectedSponsor}
                sponsorNames={eventSponsors}
            />
        );
    }

    function renderWinners() {
        if (!contenders || contenders.length === 0) {
            return null;
        } else {
            return <WinnerSection winners={contenders} />;
        }
    }

    function renderPollsAndPredictions() {
        if (!predictions || predictions.length === 0) {
            return 'Waiting for prediction Data';
        } else {
            const predictionsToUse =
                selectedSponsor === 'All'
                    ? predictions
                    : filteredPredictions(predictions);

            const polls = predictionsToUse.filter(
                (prediction: TallyPrediction) => prediction.type === 'POLL'
            );

            const sponsoredPredictions = predictionsToUse.filter(
                (prediction: TallyPrediction) => !!prediction.sponsorshipUnit
            );
            return (
                <>
                    <SponsorWinners predictions={sponsoredPredictions} />
                    <PollSection polls={polls} />
                    <PredictionTable predictions={predictionsToUse} />
                </>
            );
        }
    }

    function renderApp() {
        if (!events || events.length === 0 || !selectedEvent) {
            return 'No Events for partner. What can we show them?';
        }

        return (
            <AppContext.Provider
                value={{
                    events,
                    partnerId:
                        partnerName === SUPER_USER
                            ? superUserPartner
                            : partnerName,
                    selectedEvent,
                }}
            >
                {renderDashboardHeader()}
                {renderFilterBar()}
                {renderWinners()}
                {renderPollsAndPredictions()}
                {partnerName === SUPER_USER && (
                    <AdminFooter partnerChanged={superUserPartnerChanged} />
                )}
            </AppContext.Provider>
        );
    }

    function filteredPredictions(
        currentPredictions: TallyPrediction[]
    ): TallyPrediction[] {
        return currentPredictions.filter(
            (prediction: TallyPrediction) =>
                prediction.sponsorshipUnit &&
                prediction.sponsorshipUnit.sponsorName === selectedSponsor
        );
    }

    function getSponsorsForEvent(
        allPredictions: TallyPrediction[]
    ): string[] | undefined {
        if (!selectedEvent) {
            return undefined;
        }
        const sponsorNamesDict: { [key: string]: string } = {};
        allPredictions.forEach((prediction: TallyPrediction) => {
            const { sponsorshipUnit } = prediction;
            if (sponsorshipUnit) {
                sponsorNamesDict[sponsorshipUnit.sponsorName] =
                    sponsorshipUnit.sponsorName;
            }
        });
        const sponsorNames = Object.keys(sponsorNamesDict);
        return sponsorNames.length === 0 ? undefined : sponsorNames;
    }

    return (
        <div id="Partner-Dashboard">
            <NavBar />
            {renderApp()}
        </div>
    );
};

export default PartnerDashboard;
