import React, { useState, useContext } from 'react';
import styled from '@emotion/styled';
import LoadingOverlay from 'react-loading-overlay';

import { TColor, MOBILE_WIDTH } from '../styles/StyleConstants';
import { TallyPrediction, TallyOption } from '../util/PortalDao';
import {
    getPredictionDetailText,
    formatUserPredictionInfo,
    downloadCSV,
} from '../util/DataHelpers';
import ProgressBar from './visualizations/ProgressBar';
import PortalButton, { IconType, ButtonType } from './PortalButton';
import AppContext from '../contexts/AppContext';
import ApiContext from '../contexts/ApiContext';

interface Props {
    eventUserCount: number;
    poll: TallyPrediction;
}

const DOWNLOAD_BTN_STYLE = {
    bgColor: TColor.P5_ULTRA_LIGHT_BLUE,
    hoverBgColor: TColor.P3_TEAL,
    hoverTextColor: TColor.P1_WHITE,
    textColor: TColor.P2_SEAFOAM,
};

const PollCard = (props: Props) => {
    const { eventUserCount, poll } = props;
    const { options, totalUsersCount } = poll;
    const [isHovered, setIsHovered] = useState(false);
    const [loadingUserData, setLoadinguserData] = useState(false);
    const { getPredictionDetails } = useContext(ApiContext);
    const { selectedEvent } = useContext(AppContext);

    async function handleDownloadPoll() {
        const { id: eventId } = selectedEvent;
        setLoadinguserData(true);
        const pollDetails = await getPredictionDetails(poll.id);
        const formattedData = formatUserPredictionInfo({
            eventId,
            prediction: poll,
            details: pollDetails,
            sponsoredPrediction: false,
        });
        setLoadinguserData(false);
        const filename = `${poll.text}_details.csv`;
        downloadCSV(formattedData, filename);
    }

    function buildDataViz(option: TallyOption) {
        const pickedPercent = `${(
            (option.count / totalUsersCount) *
            100
        ).toFixed(0)}%`;
        return (
            <OptionDataContainer key={option.id}>
                <OptionText>{option.text}</OptionText>
                <VisualizationContainer>
                    <ProgressBar
                        bgColor={TColor.P4_LIGHT_BLUE}
                        fgColor={TColor.P2_SEAFOAM}
                        pixelHeight={5}
                        percentFull={pickedPercent}
                        width={'100%'}
                    />
                    <OptionPercentText>{pickedPercent}</OptionPercentText>
                </VisualizationContainer>
            </OptionDataContainer>
        );
    }

    function getPercentResponders(): string {
        return `${((poll.totalUsersCount / eventUserCount) * 100).toFixed(0)}%`;
    }

    return (
        <Container
            id={poll.id}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
        >
            <LoadingOverlay
                active={loadingUserData}
                spinner={true}
                styles={{
                    overlay: (base: any) => ({
                        ...base,
                        background: 'rgba(0, 0, 0, 0)',
                    }),
                    spinner: (base: any) => ({
                        ...base,
                        width: '100px',
                        '& svg circle': {
                            stroke: 'rgba(0, 0, 0, 1)',
                        },
                    }),
                }}
            >
                <TopLineContainer>
                    <ReleaseDetails>
                        {getPredictionDetailText(poll)}
                    </ReleaseDetails>
                    <PortalButton
                        buttonStyle={DOWNLOAD_BTN_STYLE}
                        buttonType={ButtonType.ICON}
                        clickHandler={handleDownloadPoll}
                        iconType={IconType.DOWNLOAD}
                        opacity={isHovered ? 1 : 0}
                        text=""
                    />
                </TopLineContainer>
                <PollTitle>{poll.text}</PollTitle>
                <ResponseDetails>
                    {totalUsersCount} Responses ({getPercentResponders()})
                </ResponseDetails>
                <OptionsContainer>
                    {options.map((option: any) => buildDataViz(option))}
                </OptionsContainer>
                {poll.sponsorshipUnit && (
                    <SponsorImage
                        alt={`${poll.sponsorshipUnit.sponsorName} image`}
                        src={poll.sponsorshipUnit.sponsorLogoUrl}
                    />
                )}
            </LoadingOverlay>
        </Container>
    );
};

const Container = styled.div`
    box-sizing: border-box;
    background-color: ${TColor.P1_WHITE};
    padding: 18px 40px 40px;
    border: 1px solid ${TColor.G4_LIGHT_GRAY};
    width: 49%;
    margin-bottom: 20px;
    max-width: 502px;
    min-width: 300px;
    transition: all 300ms;
    :hover {
        border-color: ${TColor.P4_LIGHT_BLUE};
        background-color: ${TColor.P5_ULTRA_LIGHT_BLUE};
    }

    @media (max-width: ${MOBILE_WIDTH}) {
        width: 100%;
    }
`;

const TopLineContainer = styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    align-items: baseline;
`;

const ReleaseDetails = styled.span`
    opacity: 0.5;
    color: ${TColor.G1_BLACK};
    font-size: 12px;
    font-style: italic;
    letter-spacing: 1.29px;
    line-height: 18px;
    text-transform: uppercase;
`;

const PollTitle = styled.h5`
    color: ${TColor.G1_BLACK};
    font-size: 21px;
    font-weight: 600;
    letter-spacing: 0.2px;
    line-height: 25px;
`;

const ResponseDetails = styled.p`
    opacity: 0.3;
    color: ${TColor.G1_BLACK};
    font-size: 21px;
    font-weight: 600;
    letter-spacing: 0.2px;
    line-height: 25px;
`;

const OptionsContainer = styled.div`
    margin-top: 20px;
`;

const OptionDataContainer = styled.div`
    display: flex;
    flex-direction: column;
`;

const VisualizationContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
`;

const OptionText = styled.p`
    color: ${TColor.G1_BLACK};
    font-size: 16px;
    line-height: 22px;
    margin-bottom: -3px;
`;

const OptionPercentText = styled.span`
    opacity: 0.5;
    color: ${TColor.G1_BLACK};
    font-size: 13px;
    font-weight: 600;
    letter-spacing: 1px;
    line-height: 18px;
    width: 50px;
    margin-left: 15px;
`;

const SponsorImage = styled.img`
    max-height: 75px;
    max-width: 75px;
`;

export default PollCard;
