import React, { useContext, useState } from 'react';
import styled from '@emotion/styled';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { makeStyles } from '@material-ui/core/styles';
import { CSVLink } from 'react-csv';

import {
    DESKTOP_CONTAINER_PADDING,
    TColor,
    MAX_WIDTH,
    MOBILE_CONTAINER_PADDING,
    MOBILE_WIDTH,
} from '../styles/StyleConstants';
import { TallyPrediction } from '../util/PortalDao';
import PortalButton, {
    IconType,
    BUTTON_DEFAULT_STYLES,
    ButtonType,
} from './PortalButton';
import ProgressBar from './visualizations/ProgressBar';
import {
    downloadCSV,
    formatPredictionsForDownload,
    getPredictionDetailText,
    numberWithCommas,
    formatUserPredictionInfo,
} from '../util/DataHelpers';
import AppContext from '../contexts/AppContext';
import ApiContext from '../contexts/ApiContext';

interface Props {
    predictions: TallyPrediction[];
}

const DOWNLOAD_BTN_STYLE = {
    bgColor: TColor.P1_WHITE,
    hoverBgColor: TColor.P3_TEAL,
    hoverTextColor: TColor.P1_WHITE,
    textColor: TColor.P3_TEAL,
};

const DOWNLOAD_BUTTON_HEIGHT = '40px';

const CUSTOM_DOWNLOAD_BUTTON_STYLE = 'position:absolute;right:-45px;';

const PredictionTable = (props: Props) => {
    const { predictions } = props;
    const { selectedEvent } = useContext(AppContext);
    const { getPredictionDetails } = useContext(ApiContext);
    const { shortName, playerCount } = selectedEvent;
    const classes = useStyles();
    const [downloadingUsersData, setDownloadingUsersData] = useState(false);

    if (predictions.length === 0) {
        return null;
    }

    const getCorrectAnswerPercent = (prediction: TallyPrediction): string => {
        const {
            correctAnswer,
            correctAnswerCount,
            totalUsersCount,
        } = prediction;
        if (!correctAnswer || !correctAnswerCount) {
            return 'N/A';
        }
        const correctAnswerPercent =
            (correctAnswerCount / totalUsersCount) * 100;
        return `${correctAnswerPercent.toFixed(0)}%`;
    };

    async function handleDownloadPrediction(prediction: TallyPrediction) {
        const { id: eventId } = selectedEvent;
        setDownloadingUsersData(true);
        const predictionDetails = await getPredictionDetails(prediction.id);
        const formattedData = formatUserPredictionInfo({
            eventId,
            prediction,
            details: predictionDetails,
            sponsoredPrediction: false,
        });
        setDownloadingUsersData(false);
        const filename = `${prediction.text}_details.csv`;
        downloadCSV(formattedData, filename);
    }

    function renderTitle() {
        return (
            <TitleArea>
                <ComponentTitle>Predictions</ComponentTitle>
                <CSVLink
                    data={formatPredictionsForDownload(predictions)}
                    filename={`${shortName}_predictions.csv`}
                    className="btn btn-primary"
                    target="_blank"
                >
                    <PortalButton
                        buttonStyle={BUTTON_DEFAULT_STYLES}
                        iconType={IconType.DOWNLOAD}
                        text="Summary"
                    />
                </CSVLink>
            </TitleArea>
        );
    }

    function renderUserCountProgressBar(prediction: TallyPrediction) {
        const answerPercent = (prediction.totalUsersCount / playerCount) * 100;
        const answerPercentString = `${answerPercent.toFixed(0)}%`;
        return (
            <ProgressBar
                bgColor={TColor.P4_LIGHT_BLUE}
                fgColor={TColor.P2_SEAFOAM}
                pixelHeight={5}
                width={'100%'}
                percentFull={answerPercentString}
            />
        );
    }

    function renderUserPercentAnsweredProgressBar(prediction: TallyPrediction) {
        const percentAnswered = getCorrectAnswerPercent(prediction);
        return (
            <ProgressBar
                bgColor={TColor.P4_LIGHT_BLUE}
                fgColor={TColor.P2_SEAFOAM}
                pixelHeight={5}
                width={'100%'}
                percentFull={percentAnswered}
            />
        );
    }

    // Release time and milestone
    function renderPredictionDetailText(prediction: TallyPrediction) {
        return (
            <ReleaseDetails>
                {getPredictionDetailText(prediction)}
            </ReleaseDetails>
        );
    }

    function renderPredictionText(prediction: TallyPrediction) {
        return <PredictionText>{prediction.text}</PredictionText>;
    }

    function renderPredictionAnswer(prediction: TallyPrediction) {
        return (
            <PredictionAnswerText>
                {prediction.correctAnswer || 'N/A'}
            </PredictionAnswerText>
        );
    }

    function renderSponsor(prediction: TallyPrediction) {
        if (!prediction.sponsorshipUnit) {
            return null;
        }
        return (
            <SponsorImage
                alt={`${prediction.sponsorshipUnit.sponsorName} logo`}
                src={prediction.sponsorshipUnit.sponsorLogoUrl}
            />
        );
    }

    function renderMobileView() {
        if (predictions.length === 0) {
            return null;
        }
        return (
            <MobileContainer>
                {predictions.map(
                    (prediction: TallyPrediction, index: number) => (
                        <MobilePredictionContainer key={index}>
                            <ReleaseAndSponsorContainer>
                                {renderPredictionDetailText(prediction)}
                                {renderSponsor(prediction)}
                            </ReleaseAndSponsorContainer>
                            {renderPredictionText(prediction)}
                            {renderPredictionAnswer(prediction)}
                            <MobileDataRowContainer>
                                <MobileVisualizationContainer>
                                    {renderUserCountProgressBar(prediction)}
                                    <div style={{ display: 'flex' }}>
                                        <PredictionDataText>
                                            {numberWithCommas(
                                                prediction.totalUsersCount
                                            )}
                                        </PredictionDataText>
                                        <PredictionDataLabel>
                                            ppl
                                        </PredictionDataLabel>
                                    </div>
                                </MobileVisualizationContainer>
                                <MobileVisualizationContainer>
                                    {renderUserPercentAnsweredProgressBar(
                                        prediction
                                    )}
                                    <div style={{ display: 'flex' }}>
                                        <PredictionDataText>
                                            {getCorrectAnswerPercent(
                                                prediction
                                            )}
                                        </PredictionDataText>
                                        <PredictionDataLabel>
                                            correct
                                        </PredictionDataLabel>
                                    </div>
                                </MobileVisualizationContainer>
                            </MobileDataRowContainer>
                        </MobilePredictionContainer>
                    )
                )}
            </MobileContainer>
        );
    }

    return (
        <ComponentContainer>
            <WidthContainer style={{ opacity: downloadingUsersData ? 0.4 : 1 }}>
                {renderTitle()}
                {renderMobileView()}
                <PTable>
                    <TableHead>
                        <TableRow>
                            <TableCell className={classes.firstColumnCell}>
                                <TableHeader>QUESTION</TableHeader>
                            </TableCell>
                            <TableCell className={classes.tableCell}>
                                <TableHeader>ANSWER</TableHeader>
                            </TableCell>
                            <TableCell className={classes.tableCell}>
                                <TableHeader>RESPONSES</TableHeader>
                            </TableCell>
                            <TableCell className={classes.tableCell}>
                                <TableHeader style={{ whiteSpace: 'nowrap' }}>
                                    % CORRECT
                                </TableHeader>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {predictions.map((prediction: TallyPrediction) => (
                            <TableRow key={prediction.id}>
                                <TableCell
                                    className={classes.firstColumnCell}
                                    component="th"
                                    scope="row"
                                >
                                    {renderPredictionText(prediction)}
                                    <ReleaseAndSponsorContainer>
                                        {renderPredictionDetailText(prediction)}
                                        {renderSponsor(prediction)}
                                    </ReleaseAndSponsorContainer>
                                </TableCell>
                                <TableCell className={classes.tableCell}>
                                    {renderPredictionAnswer(prediction)}
                                </TableCell>
                                <TableCell
                                    align="left"
                                    className={classes.tableCell}
                                    style={{ paddingRight: 0 }}
                                >
                                    <VisualizationContainer>
                                        {renderUserCountProgressBar(prediction)}
                                        <PredictionDataText>
                                            {numberWithCommas(
                                                prediction.totalUsersCount
                                            )}
                                        </PredictionDataText>
                                    </VisualizationContainer>
                                </TableCell>
                                <TableCell
                                    align="left"
                                    className={classes.tableCell}
                                    style={{ paddingRight: 0 }}
                                >
                                    <VisualizationContainer>
                                        {renderUserPercentAnsweredProgressBar(
                                            prediction
                                        )}
                                        <PredictionDataText>
                                            {getCorrectAnswerPercent(
                                                prediction
                                            )}
                                        </PredictionDataText>
                                        <PortalButton
                                            buttonStyle={DOWNLOAD_BTN_STYLE}
                                            buttonType={ButtonType.ICON}
                                            clickHandler={() =>
                                                handleDownloadPrediction(
                                                    prediction
                                                )
                                            }
                                            customStyle={
                                                CUSTOM_DOWNLOAD_BUTTON_STYLE
                                            }
                                            disabled={downloadingUsersData}
                                            iconType={IconType.DOWNLOAD}
                                            text=""
                                        />
                                    </VisualizationContainer>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </PTable>
            </WidthContainer>
        </ComponentContainer>
    );
};

const ComponentContainer = styled.div`
    background-color: ${TColor.P1_WHITE};
    font-family: Graphik Web;
    background-color: ${TColor.P1_WHITE};
    display: flex;
    justify-content: center;
    padding: ${DESKTOP_CONTAINER_PADDING};
    @media (max-width: ${MOBILE_WIDTH}) {
        padding: ${MOBILE_CONTAINER_PADDING};
    }
`;

const WidthContainer = styled.div`
    max-width: ${MAX_WIDTH};
    transition: all 300ms;
    width: 100%;
`;

const TitleArea = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
`;

const ComponentTitle = styled.h3`
    color: ${TColor.G1_BLACK};
    font-size: 24px;
    font-weight: 600;
    line-height: 18px;
`;

const PTable = styled.table`
    border-collapse: separate;
    width: 100%;
    @media (max-width: ${MOBILE_WIDTH}) {
        display: none;
    }
`;

const MobileContainer = styled.div`
    display: none;
    @media (max-width: ${MOBILE_WIDTH}) {
        display: flex;
        flex-direction: column;
    }
`;

const MobilePredictionContainer = styled.div`
    border-bottom: 1px solid ${TColor.G4_LIGHT_GRAY};
    display: flex;
    flex-direction: column;
    padding-bottom: 18px;
    padding-top: 15px;
`;

const TableHeader = styled.p`
    opacity: 0.5;
    color: ${TColor.G1_BLACK};
    font-size: 13px;
    font-family: Graphik Web;
    font-weight: 600;
    letter-spacing: 1px;
    line-height: 18px;
    text-align: left;
`;

const ReleaseDetails = styled.p`
    opacity: 0.5;
    color: ${TColor.G1_BLACK};
    font-size: 12px;
    font-style: italic;
    letter-spacing: 1.29px;
    line-height: 18px;
    text-transform: uppercase;
    @media (max-width: ${MOBILE_WIDTH}) {
        margin-bottom: 10px;
    }
`;

const SponsorImage = styled.img`
    max-height: 50px;
    max-width: 150px;
`;

const ReleaseAndSponsorContainer = styled.div`
    align-items: center;
    display: flex;
`;

const PredictionText = styled.p`
    color: ${TColor.G1_BLACK};
    font-size: 16px;
    @media (max-width: ${MOBILE_WIDTH}) {
        margin-bottom: 10px;
    }
`;

const PredictionAnswerText = styled.p`
    color: ${TColor.P2_SEAFOAM};
    font-size: 16px;
    line-height: 22px;
    @media (max-width: ${MOBILE_WIDTH}) {
        margin-bottom: 10px;
    }
`;

const VisualizationContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: ${DOWNLOAD_BUTTON_HEIGHT};
    position: relative;
`;

const MobileDataRowContainer = styled.div`
    display: flex;
    justify-content: space-between;
    margin-right: 25px;
`;

const MobileVisualizationContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 130px;
`;

const PredictionDataText = styled.p`
    color: ${TColor.G1_BLACK};
    font-size: 13px;
    font-weight: 600;
    letter-spacing: 1px;
    text-transform: uppercase;
    margin-left: 12px;
    width: 50px;
    line-height: 18px;
    @media (max-width: ${MOBILE_WIDTH}) {
        margin-left: 0;
        width: auto;
    }
`;

// Currently Mobile-only
const PredictionDataLabel = styled.span`
    opacity: 0.5;
    color: ${TColor.G1_BLACK};
    font-size: 13px;
    font-weight: 600;
    letter-spacing: 1px;
    line-height: 18px;
    margin-left: 5px;
    text-transform: uppercase;
`;

const useStyles = makeStyles(theme => ({
    firstColumnCell: {
        borderBottom: '1px solid #EFEFEF',
        maxWidth: '340px',
        paddingLeft: '0',
        verticalAlign: 'top',
        width: '40%',
    },
    table: {
        minWidth: 650,
    },
    tableCell: {
        borderBottom: '1px solid #EFEFEF',
        paddingLeft: '0',
        verticalAlign: 'top',
        width: '20%',
    },
}));

export default PredictionTable;
