import React from 'react';
import styled from '@emotion/styled';
import { TColor } from '../../styles/StyleConstants';

// TODO: Should be percent width and pixelHeight
interface Props {
    bgColor: TColor;
    fgColor: TColor;
    pixelHeight: number;
    percentFull: string;
    width: string;
    averageBarProps?: {
        avgBarPixelHeight: number;
        avgBarPixelWidth: number;
        barColor: TColor;
        borderColor: TColor;
        label: string;
        percent: number;
    };
}

export const DEFAULT_AVG_BAR = {
    avgBarPixelHeight: 18,
    avgBarPixelWidth: 7,
    barColor: TColor.P3_TEAL,
    borderColor: TColor.G1_BLACK,
};

// Used to shift the avg label halfway to the left so it's centered on avg bar.
const AVG_PCT_TEXT_WIDTH_HALVED = 9;

const ProgressBar = (props: Props) => {
    const {
        averageBarProps,
        bgColor,
        fgColor,
        pixelHeight,
        width,
        percentFull,
    } = props;

    function renderAverageBar() {
        if (!averageBarProps) {
            return;
        }
        const {
            avgBarPixelHeight,
            avgBarPixelWidth,
            barColor,
            borderColor,
            label,
            percent,
        } = averageBarProps;
        return (
            <>
                <AverageBar
                    avgBarPixelHeight={avgBarPixelHeight}
                    avgBarPixelWidth={avgBarPixelWidth}
                    barColor={barColor}
                    borderColor={borderColor}
                    percentOfBar={percent}
                />
                <AverageLabel percentOfBar={percent}>{label}</AverageLabel>
            </>
        );
    }

    return (
        <Container bgColor={bgColor} height={pixelHeight} width={width}>
            <Bar fgColor={fgColor} height={pixelHeight} width={percentFull} />
            {averageBarProps && renderAverageBar()}
        </Container>
    );
};

// eslint-disable-next-line no-unexpected-multiline
const Container = styled.div<{
    bgColor: TColor;
    height: number;
    width: string;
}>`
    background-color: ${props => props.bgColor};
    height: ${props => props.height}px;
    width: ${props => props.width};
    position: relative;
`;

const Bar = styled.div<{ fgColor: TColor; height: number; width: string }>`
    background-color: ${props => props.fgColor};
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: ${props => props.width};
`;

// eslint-disable-next-line no-unexpected-multiline
const AverageBar = styled.div<{
    barColor: TColor;
    borderColor: TColor;
    percentOfBar: number;
    avgBarPixelHeight: number;
    avgBarPixelWidth: number;
}>`
    background-color: ${props => props.barColor};
    position: absolute;
    left: ${props => props.percentOfBar}%;
    width: ${props => props.avgBarPixelWidth}px;
    border: 2px solid ${props => props.borderColor};
    height: ${props => props.avgBarPixelHeight}px;
    top: -${props => props.avgBarPixelHeight / 2 - 2}px;
`;

const AverageLabel = styled.div<{ percentOfBar: number }>`
    position: absolute;
    left: ${props => props.percentOfBar - AVG_PCT_TEXT_WIDTH_HALVED}%;
    top: 12px;
    opacity: 0.7;
    color: ${TColor.P1_WHITE};
    font-size: 12px;
    font-style: italic;
    letter-spacing: 1.29px;
    line-height: 18px;
    white-space: nowrap;
`;

export default ProgressBar;
