import React, { useState, useContext } from 'react';
import styled from '@emotion/styled';

import { TColor } from '../../styles/StyleConstants';
import {
    numberWithCommas,
    downloadCSV,
    formatEventUserData,
} from '../../util/DataHelpers';
import PortalButton, { IconType } from '../PortalButton';
import AppContext from '../../contexts/AppContext';
import ApiContext from '../../contexts/ApiContext';

interface Props {
    playerCount: number;
    runningAverage: number;
}

const BUTTON_STYLE = {
    bgColor: TColor.P2_SEAFOAM,
    hoverBgColor: TColor.P3_TEAL,
    hoverTextColor: TColor.P1_WHITE,
    textColor: TColor.P1_WHITE,
};

const PlayerTrend = (props: Props) => {
    const { playerCount, runningAverage } = props;
    const [isRequesting, setIsRequesting] = useState(false);
    const appContext = useContext(AppContext);
    const apiContext = useContext(ApiContext);

    async function handleButtonClick() {
        const { id, shortName } = appContext.selectedEvent;
        setIsRequesting(true);
        const users = await apiContext.getUsers(id);
        const formattedData = formatEventUserData(users);
        const filename = `${shortName}_users.csv`;
        downloadCSV(formattedData, filename);
        setIsRequesting(false);
    }

    function renderData() {
        const trendingUp = playerCount - runningAverage > 0;
        const ratio = Math.abs(1 - playerCount / runningAverage) * 100;
        const trendPercent = `${trendingUp ? '+' : '-'}${ratio.toFixed(1)}%`;

        const runningAverageString = numberWithCommas(
            parseFloat(runningAverage.toFixed(0))
        );

        return (
            <DataSection>
                <TrendPercent>
                    {numberWithCommas(playerCount)}&nbsp;({trendPercent})
                </TrendPercent>
                <AveragePlayers>{runningAverageString}&nbsp;AVG</AveragePlayers>
                <PortalButton
                    buttonStyle={BUTTON_STYLE}
                    clickHandler={handleButtonClick}
                    disabled={isRequesting}
                    iconType={IconType.DOWNLOAD}
                    pixelWidth={175}
                    text={isRequesting ? 'Loading...' : 'Players'}
                />
            </DataSection>
        );
    }

    return (
        <Container>
            <TopSection>
                <Title>Player trend</Title>
            </TopSection>
            {renderData()}
        </Container>
    );
};

const Container = styled.div`
    text-align: left;
`;
const TopSection = styled.div`
    border-bottom: 1px solid ${TColor.G5_GRAY};
    width: 100%;
    margin-bottom: 28px;
`;

const Title = styled.div`
    opacity: 0.5;
    color: ${TColor.P1_WHITE};
    font-size: 13px;
    font-weight: 600;
    letter-spacing: 1px;
    line-height: 18px;
    text-transform: uppercase;
    margin-bottom: 3px;
`;

const DataSection = styled.div`
    width: 100%;
`;

const TrendPercent = styled.h5`
    color: ${TColor.P4_LIGHT_BLUE};
    font-size: 24px;
    font-weight: 600;
    line-height: 18px;
    margin-bottom: 4px;
`;

const AveragePlayers = styled.p`
    opacity: 0.7;
    color: ${TColor.P1_WHITE};
    font-size: 12px;
    font-style: italic;
    letter-spacing: 1.29px;
    line-height: 18px;
    margin-bottom: 20px;
`;

export default PlayerTrend;
