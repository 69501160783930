import { TallyEvent } from './PortalDao';

// Outputs format [month abbreviation] [day]
// e.g. Sep 09

const monthNumToAbbreviation = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
];

export interface MonthAndDay {
    month: string;
    day: string;
}

export interface MonthAndDayAndHour extends MonthAndDay {
    hour: string;
}

export const dateToMonthNameAndDay = (date: Date): MonthAndDay => {
    const monthNum = date.getMonth();
    const day = `${date.getDate()}`;
    const month = monthNumToAbbreviation[monthNum];
    return { day, month };
};

export const dateToMonthNameAndDayAndHour = (
    date: Date
): MonthAndDayAndHour => {
    const monthAndDay = dateToMonthNameAndDay(date);
    const hour = date.getHours();
    const minutes = date.getMinutes();
    const hourFixed = hour > 12 ? `${hour - 12}` : hour;
    const minuteString = minutes === 0 ? '00' : `${minutes}`;
    const hourString = `${hourFixed}:${minuteString}${hour > 12 ? 'PM' : 'AM'}`;
    return { month: monthAndDay.month, day: monthAndDay.day, hour: hourString };
};

export const buildDateDictionary = (
    data: TallyEvent[]
): { [key: string]: string } => {
    const dictionary: { [key: string]: string } = {};
    for (const event of data) {
        const startDate = new Date(event.startDate).toDateString();
        dictionary[event.id] = startDate;
    }
    return dictionary;
};
